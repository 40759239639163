import React from "react"
import { graphql } from "gatsby"
import { AnimatedBg, Transition } from "scroll-background"

// Layout
import Layout from "../../components/Layout"
// import SEO from "../../components/SEO"

// Components
import ArticleHeader from "../../components/ArticleHeader"
// import Image from '../../components/Image.js'
import MoreStories from "../../components/MoreStories"

export const pageQuery = graphql`
  query MySpecialQuery($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    assetsYaml(locale: { eq: $locale }) {
      locale
      languagesMenu {
        text
        href
      }
      logo {
        title
        url
      }
      mainMenu {
        text
        href
      }
      mainMenuButtons {
        text
        href
      }
      footerTagLine
      socialMenu {
        text
        href
      }
      footerMenu {
        text
        href
      }
    }
  }
`

export default function Home({ data }) {
  const localeSettings = data.assetsYaml

  return (
    <Layout settings={localeSettings}>
      <AnimatedBg>
        <ArticleHeader
          overline={<p>Example Special Page</p>}
          headline={<h1>Vivamus sagittis lacus vel augue laoreet.</h1>}
          subheadline={
            <p>
              Nullam id dolor id nibh ultricies vehicula ut id elit. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Fusce dapibus, tellus
              ac cursus commodo.
            </p>
          }
        />
        <Transition from="#08f7fe" to="#08f7fe" position="0.75">
          <p className="lead">
            Donec sed odio dui. Curabitur blandit tempus porttitor. Vestibulum
            id ligula porta felis euismod semper. Aenean lacinia bibendum nulla
            sed consectetur. Vivamus sagittis lacus vel augue laoreet rutrum
            dolor auctor.
          </p>
          <p>Story Shogo Hagiwara / Photography Eric Micotto</p>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Maecenas faucibus
            mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia
            quam venenatis vestibulum. Curabitur blandit tempus porttitor.
            Curabitur blandit tempus porttitor.
          </p>
          <p>
            Cum sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Cras mattis consectetur purus sit amet
            fermentum. Maecenas faucibus mollis interdum. Nullam id dolor id
            nibh ultricies vehicula ut id elit. Nulla vitae elit libero, a
            pharetra augue. Praesent commodo cursus magna, vel scelerisque nisl
            consectetur et.
          </p>
        </Transition>

        <Transition from="#08f7fe" to="#09fbd3" position="0.75">
          <hr />
          <p>
            Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo
            risus, porta ac consectetur ac, vestibulum at eros. Vestibulum id
            ligula porta felis euismod semper. Maecenas sed diam eget risus
            varius blandit sit amet non magna. Donec ullamcorper nulla non metus
            auctor fringilla. Nullam quis risus eget urna mollis ornare vel eu
            leo. Maecenas sed diam eget risus varius blandit sit amet non magna.
          </p>
          <p>
            Donec sed odio dui. Aenean lacinia bibendum nulla sed consectetur.
            Cras mattis consectetur purus sit amet fermentum. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor. Praesent
            commodo cursus magna, vel scelerisque nisl consectetur et. Cras
            justo odio, dapibus ac facilisis in, egestas eget quam. Aenean eu
            leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          </p>
          <p>
            Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit
            libero, a pharetra augue. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Donec sed odio dui. Donec id elit non mi porta
            gravida at eget metus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit.
          </p>
        </Transition>

        <Transition from="#09fbd3" to="#f5d300" position="0.75">
          <p>
            Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
            nibh, ut fermentum massa justo sit amet risus. Vestibulum id ligula
            porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet
            rutrum faucibus dolor auctor. Integer posuere erat a ante venenatis
            dapibus posuere velit aliquet. Maecenas sed diam eget risus varius
            blandit sit amet non magna. Vivamus sagittis lacus vel augue laoreet
            rutrum faucibus dolor auctor.
          </p>
          <hr />
          <p>
            Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean
            eu leo quam. Pellentesque ornare sem lacinia quam venenatis
            vestibulum. Curabitur blandit tempus porttitor. Etiam porta sem
            malesuada magna mollis euismod. Nullam quis risus eget urna mollis
            ornare vel eu leo. Praesent commodo cursus magna, vel scelerisque
            nisl consectetur et. Maecenas sed diam eget risus varius blandit sit
            amet non magna.
          </p>
          <p>
            Donec id elit non mi porta gravida at eget metus. Cras mattis
            consectetur purus sit amet fermentum. Nullam id dolor id nibh
            ultricies vehicula ut id elit. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Donec sed odio dui. Praesent
            commodo cursus magna, vel scelerisque nisl consectetur et. Aenean eu
            leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          </p>
        </Transition>

        <Transition from="#f5d300" to="#fe53bb" position="0.75">
          <p>
            Nullam quis risus eget urna mollis ornare vel eu leo. Sed posuere
            consectetur est at lobortis. Nullam quis risus eget urna mollis
            ornare vel eu leo. Morbi leo risus, porta ac consectetur ac,
            vestibulum at eros. Vestibulum id ligula porta felis euismod semper.
          </p>
          <hr />
          <p>
            Donec sed odio dui. Aenean lacinia bibendum nulla sed consectetur.
            Cras mattis consectetur purus sit amet fermentum. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor. Praesent
            commodo cursus magna, vel scelerisque nisl consectetur et. Cras
            justo odio, dapibus ac facilisis in, egestas eget quam. Aenean eu
            leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          </p>
          <p>
            Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Etiam
            porta sem malesuada magna mollis euismod. Praesent commodo cursus
            magna, vel scelerisque nisl consectetur et. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis
            vestibulum. Curabitur blandit tempus porttitor. Donec sed odio dui.
          </p>
        </Transition>

        {/* <MoreStories /> */}
      </AnimatedBg>
    </Layout>
  )
}
