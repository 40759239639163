import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { findLocaleContentFromList } from '../utils/find-locale-content-from-list'

export const TranslateTextContent = ({ locale, translationKey, translations }) => {
  const translateNameLowerCase = translationKey.toLowerCase()

  let text = ''
  const localeContent = findLocaleContentFromList(locale, translations)
  if (localeContent) {
    const translation = localeContent.contentTranslations
      .filter(item => item.name.toLowerCase() === translateNameLowerCase)
      .shift()

    if (translation !== undefined) {
      text = translation.text
    }
  }

  return <span>{text}</span>
}

export default function TranslateText({ name }) {
  const result = useStaticQuery(
    graphql`
      query {
        allAssetsYaml {
          edges {
            node {
              id
              locale
              translations {
                name
                text
              }
            }
          }
        }
        wp {
          mazdaStoriesLocaleContent {
            locale
            contentTranslations {
              name
              text
            }
          }
        }
      }
    `
  )

  const currentLocale = process.env.LOCALE
  const translations = result.wp.mazdaStoriesLocaleContent

  return (
    <TranslateTextContent
      translationKey={name}
      translations={translations}
      locale={currentLocale}
    />
  )
}
