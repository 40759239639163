/**
 * External Dependencies
 */
import React, { useRef } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { Swiper, SwiperSlide } from "swiper/react"
import shortid from "shortid"
import sanitizeHtml from "sanitize-html"

import parseContent from "../utils/parse-content"
import TranslateText from "./TranslateText"
import SwiperNavButtons from "./SwiperNavButtons"
import WordpressImage from "./WordpressImage"

const hasArticleHeaderFirst = (content) => {
  return (
    // This doesn't work on staging for some reason and needs investigation
    // Think it's because on build, function names change if not on local/dev
    // content[0]?.type?.name === "ArticleHeader" &&
    // !!content[0]?.props?.headline?.props?.children
    // so using a hacky method, assume ArticleHeader will
    // have headline, subheadline AND overline present
    !!content[0]?.props?.headline?.props?.children &&
    !!content[0]?.props?.subheadline &&
    !!content[0]?.props?.overline
  )
}

const getArticleHeaderHeadline = (content) => {
  return content[0]?.props?.headline?.props?.children
}

export default function MoreStories({ stories, background }) {

  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const slidesPerView = 1.1
  const showSwiperScrollNav = (stories.length > (3 * slidesPerView))
  // Think we need a dark/light custom field too
  // And maybe another field for the intro text, excerpt may not be suitable
  return (
    <div
      className={classNames({
        "component component-more-stories alignfull": true,
        light: background === "light",
      })}
    >
      <div className="container">
        <h2 className="display-5">
          <TranslateText name="more_stories" />
        </h2>
        <Swiper
          breakpoints={{
            768: {
              grabCursor: true,
              slidesPerView: 3,
            },
          }}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          observer
          observeParents
          onSwiper={swiper => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              if (swiper.params?.navigation) {
                // Override prevEl & nextEl now that refs are defined
                // eslint-disable-next-line no-param-reassign
                swiper.params.navigation.prevEl = prevRef.current
                // eslint-disable-next-line no-param-reassign
                swiper.params.navigation.nextEl = nextRef.current

                // Re-init navigation
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              }
            })
          }}
          pagination
          preventClicks={false}
          preventClicksPropagation={false}
          scrollbar={{ draggable: true }}
          spaceBetween={33}
          slidesPerView={slidesPerView}
          slideToClickedSlide={true}
        >
          {!!stories &&
            stories.map(item => {
              if (!item) {
                return <span key={shortid.generate()} />
              }

              const {
                excerpt,
                nodeType,
                title,
                content,
                uri,
              } = item

              // Not using destructure as it will error
              // if content hasn't got featuredImage set in WordPress
              // const src = item.featuredImage?.node?.localFile?.childImageSharp?.fluid?.src
              const src = item.featuredImage?.node?.localFile?.publicURL;
              const mobileSrc = (item.mobileFeaturedImageUrl) ? item.mobileFeaturedImageUrl : '';

              // Content editors are entering soft hyphens (&shy;) into article headers
              // And we want the soft hyphens to show up here on the more stories titles
              // So we have to parse the content to get the article header content
              const parsedContent = content ? parseContent(content) : ""
              const moreStoriesTitle = hasArticleHeaderFirst(parsedContent)
                ? getArticleHeaderHeadline(parsedContent)
                : title

              return (
                <SwiperSlide key={shortid.generate()}>
                  <Link to={item.uri}>
                    {/* <img className="img-fluid" src={src} alt="Placeholder" /> */}
                    <WordpressImage
                      preventReveal
                      src={item.featuredImage?.node?.localFile?.publicURL}
                      mobileSrc={mobileSrc}
                    />
                  </Link>

                  <div className="more-stories-content">
                    <div className="overline">
                      <TranslateText name={nodeType} />
                    </div>

                    <h3 className="headline">{moreStoriesTitle}</h3>

                    <div
                      className="excerpt"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(excerpt),
                      }}
                    />
                  </div>

                  {/* <Link to={uri} className="read-story d-none d-md-block"> */}
                  <Link to={item.uri} className="read-story">
                    <TranslateText name="read_story" />
                  </Link>
                </SwiperSlide>
              )
            })}

            {showSwiperScrollNav && (
              <SwiperNavButtons ref={{ prevRef, nextRef}} />
            )}
        </Swiper>
      </div>
    </div>
  )
}
